import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { checkUserSession } from "../external_api/User";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, clearState, userSelector } from '../store/slices/user';
import { AppDispatch } from "../store";

const useSessionCheck = (interval: number = 30000) => { // default interval set to 30 seconds
    const [isSessionActive, setIsSessionActive] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const checkSession = async () => {
        var checkSessionResponse = await checkUserSession();
        //console.log("checkSession", checkSessionResponse)
        var { data, status, errorMessage } = checkSessionResponse;
       /* console.error('Session check status:', status);*/
        if (status === 200) {
            //setRoleList(data);
            setIsSessionActive(data.session);
            if(data.session) {
              
            } else {
                dispatch(clearState());
                localStorage.clear();
                navigate("/");
            }
            //setIsSessionActive(response.data.session);

        } else {
            //console.error('Session check failed:', error);
            dispatch(clearState());
            localStorage.clear();
            if (window.location.href.indexOf("reset-password") === -1){
                navigate("/");
            }
            //navigate("/");
            /*if(errorMessage == "Unauthenticated.") {
            }*/
            console.error('Session check failed:');
            setIsSessionActive(false);
        }
    };

    useEffect(() => {
        checkSession(); // Initial check on mount

        const timer = setInterval(() => {
            checkSession(); // Periodic session check
        }, interval);

        // Cleanup timer on unmount
        return () => clearInterval(timer);
    }, [interval]);


    
    return isSessionActive;
};

export default useSessionCheck;
